<template lang="pug">
article(:class='{ shadowed: withShadow, simple: simple }', @click.stop='handleClick()')
  .card-header(:style='{ backgroundColor: course.data.color }', v-if='!simple')
    //- p.card-header__text(v-if='course.data.card_header_text[0]') {{ course.data.card_header_text[0].text }}
    h6.card-header__text {{ course.data.title[0].text }}
    .card-header__price-cap
      span(v-if='course.data.price') {{ course.data.price[0].text }}
  .card-body
    h6(v-if='simple') {{ course.data.title[0].text }}
    ul.card-body__profits
      li.card-body__profits__profit(v-for='profit in course.data.profits') 
        | {{ profit.text }}
    common-button.card-body__programme-link(
      v-if='!simple',
      label='Plus sur ce programme',
      variant='orange',
      :path='{ name: "CourseItem", params: { uid: course.uid } }'
    )
</template>

<script>
export default {
  name: 'CourseAbstractCard',
  components: {
    CommonButton: () => import('@/components/CommonButton')
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    withShadow: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (this.simple) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

article {
  font-family: 'Hind Vadodara', sans-serif;

  * {
    box-sizing: border-box;
  }

  height: 350px;
  width: 350px;
  min-width: 300px;
  max-width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin: 1rem;

  &.shadowed {
    box-shadow: 0px 8px 12px 0px rgba(150, 150, 150, 0.4);
  }

  &.simple {
    height: 250px;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;

    .card-body {
      height: 100%;
    }

    &:hover {
      box-shadow: 0px 15px 20px 0px rgba(150, 150, 150, 0.4);
    }
  }

  .card-header {
    padding: 1rem;
    height: 25%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__text {
      font-weight: bold;
      font-size: 1.1rem;
    }

    &__price-cap {
      background-color: $white;
      width: fit-content;
      padding: 0.4rem 0.8rem;
      border-radius: 20px;
      font-family: 'Libre Franklin', sans-serif;
      font-weight: 500;
      font-size: 0.8rem;
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .card-body {
    height: 250px;
    padding: 1rem;
    height: 75%;
    display: flex;
    flex-direction: column;
    background-color: $white;

    h6 {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    &__profits {
      margin-top: 0.5rem;
      font-family: 'Libre Franklin', sans-serif;
      line-height: 1.3;
      font-size: 1.05rem;

      &__profit {
        margin-bottom: 1rem;
        display: flex;
        &::before {
          content: '';
          display: inline-block;
          height: 20px;
          width: 20px;
          background-image: url('../../assets/feu.svg');
          margin-right: 0.5rem;
          margin-top: -0.2rem;
        }

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    &__programme-link {
      font-family: 'Libre Franklin', sans-serif;
      font-weight: 600;
      align-self: flex-end;
      margin-top: auto;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $darkBlue;

      span {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
